<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body class="mt-md-1">
        <b-overlay variant="white" spinner-variant="primary" blur="0" opacity=".75" rounded="sm" :show="isLoading">
          <div class="m-1">
            <b-row>
              <b-col cols="12" md="12"><h3>Danh sách lớp tín chỉ đã khảo sát/đánh giá</h3></b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  row-style-class="vgt-row"
                  styleClass="vgt-table striped bordered"
                  :line-numbers="true"
                  :columns="columns"
                  :rows="mainData"
                  max-height="400px"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'action'" class="thead-group-action">
                      <b-button-group>
                        <b-button
                          v-b-modal.evaluateCreditClassSaveModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          title="Xem chi tiết"
                          @click="onEditMainData(props.row)"
                        >
                          <feather-icon icon="EyeIcon" />
                        </b-button>
                      </b-button-group>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
          <CreditClassSave
            ref="criteriaSaveFormRef"
            :mainData="currentMainData"
            :setOfCriteriaObjectId="dataSend.setOfCriteriaObjectId"
            @succeed="onSucceed"
          />
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import CreditClassSave from '@/views/evaluate-management/CreditClassSave.vue'
import { CREDIT_CLASS_STATUSES } from '@/const/status'
import { getUser } from '@/auth/utils'

export default {
  name: 'CreditClasses',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    CreditClassSave,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  props: {
    dataSend: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {},
      currentMainData: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Mã lớp học',
          field: 'creditClassCode',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Mã học phần',
          field: 'subjectCode',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tên học phần',
          field: 'subjectName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Giảng viên',
          field: 'teachers',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      mainData: 'creditClass/creditClassesBySetOfCriteriaObjectDataLists',
    }),
  },
  async created() {
    this.isLoading = true
    try {
      this.updateParams({ organizationId: this.user.orgId, evaluateObjectGroupId: this.dataSend.evaluateObjectGroupId, studentId: this.dataSend.studentId, includeCreditClassEvaluated: 1 })
      this.getMainData(this.filter)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: 'creditClass/getCreditClassesBySetOfCriteriaObject',
    }),
    onCreateCriteria() {
      this.currentMainData = undefined
    },
    onEditMainData(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onSucceed() {
      await this.getMainDataFromStore()
      this.$bvModal.hide('modal-replace-thesis-subject')
    },
    getStatusName(id) {
      return CREDIT_CLASS_STATUSES.find(status => status.value === id).label
    },
  },
  mounted() {
    const grid = document.querySelector('.vgt-responsive')
    grid.classList.add('overlay')
    grid.classList.add('custom')
  },
}
</script>
